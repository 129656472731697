import React, { Fragment } from "react"

const Textarea = ({
  label,
  id,
  name,
  rows,
  placeholder,
  required,
  onChange,
  onKeyDown,
  value,
  asFragment,
  className,
}) => {
  let Tag = "div"
  if (asFragment) {
    Tag = Fragment
  }

  return (
    <Tag>
      {label && (
        <label className={`block text-xs mb-1`} htmlFor={id}>
          {label}
          {required && "*"}
        </label>
      )}
      <textarea
        cols="50"
        rows={rows ? rows : "4"}
        className={`w-full p-4 leading-tight bg-white border appearance-none focus:outline-none focus:ring-1 focus:ring-black ${
          className ? className : ""
        }`}
        id={id}
        name={name}
        placeholder={placeholder}
        required={required}
        onChange={onChange}
        onKeyDown={onKeyDown}
        value={value}
      />
    </Tag>
  )
}

export default Textarea
