import React from "react"

function Checkbox({
    label,
    id,
    name,
    text,
    required,
  }) {
  return (
    <div>
      <div className="relative flex items-start">
        <div className="flex items-center h-5 cursor-pointer">
          <input
            id={id}
            name={name}
            type="checkbox"
            className="w-6 h-6 border-gray-300 rounded focus:ring-brand text-brand"
            required={required}
          />
        </div>
        <div className="ml-3">
          <label htmlFor={id} className={`block cursor-pointer`}>
            <span className="font-bold">{label} {required && "*"}</span>
            <p id="comments-description" className="block mt-3 text-base text-gray-700">
            {text}
          </p>
          </label>
         
        </div>
      </div>
    </div>
  )
}

export default Checkbox
