import React from "react"
import { graphql } from "gatsby"
import Container from "../components/common/Container"
import Layout from "./../components/layouts/Default"
import { H2 } from "../components/typography/Headings"
import PageHeader from "../components/common/PageHeader"
import Breadcrumb from "../components/common/Breadcrumb"
import Kontakt from "../components/forms/Kontakt"

export default function Page({ data, location }) {
  return (
    <Layout location={location} title="Kontakt" desc="">
      <PageHeader title="Kontakt" image={data.header} />
      <Breadcrumb pages={[{ name: "Kontakt", to: "/kontakt/" }]} />

      <Container narrow>
        <H2 className="font-display">Kontaktdaten</H2>
        <hr className="my-8 border-t border-gray-100" />
        <div className="mb-5 grid grid-cols-2 gap-3">
          <div>
            <span className="block font-display font-bold">E-Mail:</span>
            <a
              href={`mailto:${data.site.siteMetadata.contact.mail}`}
              className="hover:underline"
            >
              {data.site.siteMetadata.contact.mail}
            </a>
          </div>
          <div>
            <span className="block font-display font-bold">Telefon:</span>
            <a
              href={`tel:${data.site.siteMetadata.contact.phoneMain.replace(
                /\s/g,
                ""
              )}`}
              className="hover:underline"
            >
              {data.site.siteMetadata.contact.phoneMain}
            </a>{" "}
            und{" "}
            <a
              href={`tel:${data.site.siteMetadata.contact.phoneSecondary.replace(
                /\s/g,
                ""
              )}`}
              className="hover:underline"
            >
              {data.site.siteMetadata.contact.phoneSecondary}
            </a>
          </div>
          <div>
            <span className="block font-display font-bold">
              Mobiltelefonnummer und WhatsApp:
            </span>
            <a
              href={`tel:${data.site.siteMetadata.contact.mobile.replace(
                /\s/g,
                ""
              )}`}
              className="hover:underline"
            >
              {data.site.siteMetadata.contact.mobile}
            </a>
          </div>
          <div>
            <span className="block font-display font-bold">Fax:</span>
            <a
              href={`fax:${data.site.siteMetadata.contact.fax.replace(
                /\s/g,
                ""
              )}`}
              className="hover:underline"
            >
              {data.site.siteMetadata.contact.fax}
            </a>
          </div>
          <div>
            <span className="block font-display font-bold">Adresse:</span>
            {data.site.siteMetadata.contact.street},<br />
            {data.site.siteMetadata.contact.plz}{" "}
            {data.site.siteMetadata.contact.city},<br />
            {data.site.siteMetadata.contact.country}
          </div>
        </div>
        <H2 className="font-display">Kontaktformular</H2>
        <hr className="my-8 border-t border-gray-100" />
        <Kontakt />
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query {
    header: file(relativePath: { eq: "pages/kontakt/header.jpg" }) {
      childImageSharp {
        gatsbyImageData(transformOptions: { cropFocus: ATTENTION }, width: 1600)
      }
    }
    peter: file(relativePath: { eq: "pages/index/peter.jpg" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
    site {
      siteMetadata {
        contact {
          city
          country
          fax
          mail
          phoneMain
          phoneSecondary
          mobile
          plz
          street
        }
      }
    }
  }
`
