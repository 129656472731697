import React from "react"
import { Link } from "gatsby"
import { AnchorLink } from "gatsby-plugin-anchor-links"


const Button = ({
  text,
  to,
  href,
  onClick,
  target,
  rel,
  submit,
  white,
  transparent,
  small,
  anchor,
  className,
}) => {
  const classes = `inline-flex items-center justify-center border border-transparent focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand transition-colors duration-300 
  
  ${white && "bg-white border-gray-300 text-black hover:bg-gray-300"}
  
  ${transparent && "bg-transparent border-white text-white hover:bg-gray-300 "}
  
  ${!transparent & !white && "bg-brand text-white hover:bg-brand-dark"}
  
  ${small ? "px-5 py-1 text-sm" : "px-5 py-3"}
  
  ${className ? className : ""}`

  if (to) {
    return (
      <Link to={to} className={classes}>
        {text}
      </Link>
    )
  }

  if (href) {
    return (
      <a href={href} className={classes} target={target} rel={rel}>
        {text}
      </a>
    )
  }
  
  if (anchor) {
    return (
      <AnchorLink to={anchor} title={text} className={classes}>
        {text}
      </AnchorLink>
    )
  }
  if (onClick) {
    return (
      <button onClick={onClick} className={classes}>
        {text}
      </button>
    )
  }

  if (submit) {
    return (
      <button type="submit" className={classes}>
        {text}
      </button>
    )
  }
  return (
    <button onClick={onClick} className={`bg-red-600 ${classes}`}>
      Missing props
    </button>
  )
}

export default Button
