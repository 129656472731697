import React from "react"
import { Link } from "gatsby"

const Breadcrumb = ({ pages, inline, className }) => {
  return (
    <nav
      className={`py-3 hidden xs:flex  bg-gray-50 ${
        className ? className : ""
      }`}
      aria-label="Breadcrumb"
    >
      <ol className="inline-flex flex-wrap justify-center px-4 mx-auto space-x-4 text-xs md:text-sm sm:px-6 lg:px-8">
        <li className="flex">
          <div className="flex items-center">
            <Link to="/" className="font-medium hover:underline">
              Start
            </Link>
          </div>
        </li>

        {pages.map((page, i) => {
          return (
            <li className="flex" key={i}>
              <div className="flex items-center">
                /
                <Link to={page.to} className="ml-4 hover:underline">
                  {page.name.split("").length > 30
                    ? page.name.slice(0, 30) + "..."
                    : page.name}
                </Link>
              </div>
            </li>
          )
        })}
      </ol>
    </nav>
  )
}

export default Breadcrumb
