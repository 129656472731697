import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { H1 } from "../typography/Headings"

function PageHeader({ title, image }) {
  return (
    <div className="relative">
      <div className="absolute inset-0 w-full h-full">
        <GatsbyImage
          image={getImage(image)}
          alt={title}
          className="absolute inset-0 w-full h-full"
        />
      </div>
      <div className="relative z-10 items-center justify-center py-8 bg-black bg-opacity-50 md:py-32 fluid">
        <div className="p-6 mx-auto text-center text-white max-w-7xl">
          <H1 display>{title}</H1>
        </div>
      </div>
    </div>
  )
}

export default PageHeader
