import React from "react"
import Input from "./items/Input"
import Textarea from "./items/Textarea"
import Button from "../common/Button"
import Checkbox from "./items/Checkbox"


function FotoreisenAnfragen({ reise, url }) {
  return (
    <form
      name="Kontatkformular"
      method="POST"
      data-netlify="true"
      action="/anfrage-gesendet/"
      className="space-y-4"
    >
      <input type="hidden" name="form-name" value="Kontatkformular" />
      <div className="grid-cols-2 gap-4 sm:grid ">
        <Input
          type="text"
          label="Vorname"
          id="vorname"
          name="Vorname"
          placeholder="Max"
          required
        />
        <Input
          type="text"
          label="Nachname"
          id="nachname"
          name="Nachname"
          placeholder="Mustermann"
          required
        />
      </div>
      <Input
        type="tel"
        label="Telefon"
        id="telefon"
        name="Telefon"
        placeholder="01234 567890"
        autoComplete="tel"
        inputMode="numeric"
        required
      />
      <Input
        type="email"
        label="E-Mail"
        id="email"
        name="E-Mail"
        placeholder="max@muster.de"
        autoComplete="email"
        required
      />
      <Textarea
        label="Kommentar"
        id="kommentar"
        name="Kommentar"
        placeholder="Deine Nachricht"
        required
      />
              <div className="space-y-5">
          <Checkbox
            label="Datenschutzerklärung"
            id="datenschutz-einwilligung"
            name="Datenschutzerklärung"
            text="Es gilt der Datenschutz des Naturblick Verlags. Ihre persönlichen Daten benötigen wir zur Einrichtung und Verwaltung Ihres Abonnements. Ich stimme zu, dass meine Angaben aus dem Bestellformular zur Bearbeitung meiner Anfrage erhoben und verarbeitet werden. Die Daten werden nach abgeschlossener Bearbeitung Ihrer Anfrage gelöscht. Hinweis: Sie können Ihre Einwilligung jederzeit für die Zukunft per E-Mail an verlag@naturblick.com widerrufen."
            required
          />
        </div>

      <Button submit text="Anfrage senden" className="w-full" />
    </form>
  )
}

export default FotoreisenAnfragen
